import * as React from 'react'
import BlogPostOld from "../../components/blog-post-old";
import image from "../../images/blog39/image10.png"
import image2 from "../../images/blog39/image5.jpg"











export default function ACLInjuriesPartTwo(){
    return(
        <BlogPostOld
            src={image}
            title={"ACL Injuries Part 2: Priority #1 on Day 1"}
            summary={"In the first part of this blog on ACL injuries, we talked about how ACL injuries have been on the rise and the unfortunate truth is that our youth athletes are the ones affected most. We also unveiled how reinjury is all too common, one in five athletes experiencing a reinjury upon returning to high-risk sports after an ACL reconstruction."}
            date={"Oct. 30, 2023"}
            author={"Dr. Donald Mull, DC"}

        >
            <>
                <p>
                    <a href={"https://kirehab.com/blog/ACL-injuries-part-one"}>In the first part of this blog on ACL injuries</a>, we talked about how ACL
                    injuries have been on the rise and the unfortunate truth is that our youth
                    athletes are the ones affected most. We also unveiled how reinjury is all
                    too common, one in five athletes experiencing a reinjury upon returning to
                    high-risk sports after an ACL reconstruction.(1)
                </p>
                <p>
                    This article is for someone who recently has an ACL injury or someone they
                    know has recently experienced an ACL injury or if you have an athlete who
                    participates regularly in a field or court sport. Even if this athlete has
                    yet to have surgery, now is the time to set them up to have the best odds
                    for a successful return to sport.&nbsp;
                </p>
                <p>
                    In my opinion, prior to surgery or as soon as possible a good place to start is to get an athlete’s baseline level of strength and power on the non-involved leg. This is the only way to get a TRUE baseline measurement for an athlete to aim for during the rehab process. An athlete who is given functional measures to train for is much more likely to succeed than one who is just given a timeline of 6-9 months to return. There is no direction in a timeline and the athlete needs to know where to go and how to get there.

                </p>
                <p>
                    Doing this as soon as possible ensures that the athlete’s strength and/or power has not diminished due to lack of sport participation. Typically the athlete goes for weeks if not months prior to testing this and in some cases never at all. So imagine you or your athlete tears their ACL, it typically takes 1-3 weeks to diagnose via MRI and an additional 2-3 weeks (if you’re lucky) to have the procedure. PT then usually starts 1-2 weeks after the surgery.

                </p>
                <p>
                    We are now at 8 weeks and if the PT clinic has the knowledge to measure the
                    strength and/power of the non-injured leg to get a baseline, but by this
                    point there has been a drastic decrease in strength and power given the
                    athlete has been sidelined awaiting diagnosis, surgery and post surgical
                    care. This baseline is grossly skewed to be much weaker and slower - this is
                    NOT the true baseline.
                </p>
                <p>
                    Now let’s fast forward 5-6 months as rehab has progressed. Most PT clinics,
                    again if they even measure outcomes, use something called limb symmetry
                    index to create their return to play criteria. Essentially they compare the
                    strength, coordination and/or power to the non surgical leg and then decide
                    how to progress rehab and/or decide what the athlete is cleared to do.&nbsp;
                </p>
                <p>
                    For example, if an athlete’s quad and hamstring on the surgically repaired
                    leg is 80 percent as strong as the non-surgical leg or can jump 80 percent
                    as far as the non-surgical leg most PT clinics will clear the athlete to run
                    and progress rehab to be more “intense”. Theoretically this makes logical
                    sense. However, the logic is flawed because the non-surgical leg is also
                    weak relative to their initial abilities.&nbsp;
                </p>
                <p>
                    Remember it has been MONTHS of deconditioning before the non surgical leg
                    has been measured.&nbsp;
                </p>
                <p>
                    This may not be an issue for the athlete in the gym or in a PT clinic when
                    performing predictable movements in a controlled setting. However, even if
                    the athlete gets to 100 percent compared to the non-surgical leg; are they
                    really as good as they were pre-injury? How will you ever know?
                </p>
                <p>
                    You can’t expect an athlete who is deconditioned, weaker and slower not to
                    have an increased risk of injury. This is in fact the perfect recipe for
                    injury. No wonder why the reinjury rate is so high.
                </p>
                <p>
                    You NEED to prioritize getting a baseline to know if the athlete is
                    physically as capable as they were pre-injury.&nbsp;
                </p>
                <p>
                    In my opinion you should test three things on the non-injured leg ASAP after
                    injuring an ACL (or if you really want to be preventative you can do this on
                    both to have a working baseline, which would be fabulous and typically only
                    reserved for professional athletes):
                </p>
                <ul className={"pl-5"}>
                    <li>Quadriceps strength</li>
                    <li>Hamstring strength</li>
                    <li>Single leg vertical jump height</li>
                </ul>
                <p>
                    To be clear, the reason I have such a strong opinion is because I have heard
                    some horror stories about the care that athletes have received. I think PT
                    manages athletes away from load for FAR too long which creates a massive gap
                    in physical capabilities and conditioning. PT should look very similar to
                    strength and conditioning as soon as possible or at least involve a strength
                    coach as soon as possible. However I will save this topic for another day,
                    maybe I will call it “Part 3: What ACL Rehab <em>Should</em> Look Like”.
                </p>
                <p>
                    Secondly, when following the research the numbers do not lie. Using an
                    athlete's strength pre-surgically instead of using limb symmetry 6 months
                    post operation is a better predictor for future injury of an athlete(2).
                    Meaning those who do reach pre-surgical strength and power are much less
                    likely to experience a re-injury.&nbsp;
                </p>
                <p>
                    In addition, if you combine both strength and hop testing in the return to
                    play criteria an athlete has been shown to be more likely to be healthy in a
                    one year follow up after returning to sport (3). This should be the goal at
                    the end of the day right?&nbsp;
                </p>
                <img src={image2}/>
                <p>
                    How often we hear an athlete not meeting these minimum requirements is
                    concerning to us. I am not naive enough to think we can prevent all
                    re-injuries, however I do think we can drastically stack the odds in the
                    favor of the athlete if we do the following during the rehab process:
                </p>
                <ul className={"pl-5"}>
                    <li>
                        Get baseline strength/power measurement when healthy or ASAP post ACL
                        injury
                    </li>
                    <li>Restore ROM early</li>
                    <li>Continue whole body strength training early and often</li>
                    <li>
                        Maintain a base of aerobic conditioning and conditioning specific to sport
                    </li>
                    <li>
                        Expose plyometrics, sprinting, cutting and reactive training weeks to
                        months prior to returning
                    </li>
                    <li>Restore strength and power to baseline prior to return to sport</li>
                </ul>
                <p>
                    We are extremely passionate about this topic because quite frankly, I feel
                    there is such an incredible opportunity to have a positive impact on the
                    athletes that have become apart of the rising statistics of an ACL tear.
                    This is why we offer free testing for those waiting or who just had an ACL
                    reconstruction (remember the earlier the better).{" "}
                    <a
                        href="https://kirehab.com/return-to-sport-testing"
                        target="_blank"
                        rel="noopener"
                    >
                        Click here
                    </a>{" "}
                    if you’d like to learn more.&nbsp;
                </p>
                <p>
                    In the meantime, I’ll get to work on Part 3: What ACL Rehab <em>Should</em>{" "}
                    Look Like.&nbsp;
                </p>
                <p>
                    <span style={{ color: "#212121" }}>Resources</span>
                </p>
                <ol>
                    <li>
                        <a
                            href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC7785893/#bibr18-1941738120912846"
                            target="_blank"
                            rel="noopener"
                        >
                            Barber-Westin S, Noyes FR. One in 5 Athletes Sustain Reinjury Upon
                            Return to High-Risk Sports After ACL Reconstruction: A Systematic Review
                            in 1239 Athletes Younger Than 20 Years. Sports Health. 2020
                            Nov/Dec;12(6):587-597. doi: 10.1177/1941738120912846. Epub 2020 May 6.
                            PMID: 32374646; PMCID: PMC7785893.
                        </a>
                    </li>
                    <li>
                        <a
                            href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC5483854/"
                            target="_blank"
                            rel="noopener"
                        >
                            Wellsandt E, Failla MJ, Snyder-Mackler L. Limb Symmetry Indexes Can
                            Overestimate Knee Function After Anterior Cruciate Ligament Injury. J
                            Orthop Sports Phys Ther. 2017 May;47(5):334-338. doi:
                            10.2519/jospt.2017.7285. Epub 2017 Mar 29. PMID: 28355978; PMCID:
                            PMC5483854.
                        </a>
                    </li>
                    <li>
                        <a
                            href="https://pubmed.ncbi.nlm.nih.gov/28990491/#:~:text=Conclusion%20The%20proportions%20of%20young,than%20those%20who%20did%20not."
                            target="_blank"
                            rel="noopener"
                        >
                            Toole AR, Ithurburn MP, Rauh MJ, Hewett TE, Paterno MV, Schmitt LC.
                            Young Athletes Cleared for Sports Participation After Anterior Cruciate
                            Ligament Reconstruction: How Many Actually Meet Recommended
                            Return-to-Sport Criterion Cutoffs? J Orthop Sports Phys Ther. 2017
                            Nov;47(11):825-833. doi: 10.2519/jospt.2017.7227. Epub 2017 Oct 7. PMID:
                            28990491.
                        </a>
                    </li>
                </ol>
            </>




        </BlogPostOld>

    )
}